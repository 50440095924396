import { useState, forwardRef, useImperativeHandle } from 'react';
import mammoth from 'mammoth';

import { getFileExtension } from '../utils/utils';

const ImportModal = forwardRef(({ setSunEditorContent }, ref) => {
  const [isActive, setActive] = useState(false);
  const [error, setError] = useState();
  const [files, setFiles] = useState([]);

  const activeClassName = isActive ? ' is-active' : '';
  const [file] = files;

  const open = () => setActive(true);
  const close = () => {setError(); setFiles([]); setActive(false) };

  useImperativeHandle(ref, () => ({ open, close }));

  const handleInputChange = (e) => {setError(); setFiles(e.target.files)};
  const handleDrop = (e) => {setError(); e.preventDefault(); setFiles(e.dataTransfer.files); };
  const handleConfirm = () => {
    if (!file) { setError('Al menos un archivo debe ser seleccionado');  return; }
    const extension = getFileExtension(file.name);
    const reader = new FileReader();
    switch (extension) {
      case 'docx':
        reader.onload = (e) => {
          const arrayBuffer = e.target.result;
          mammoth.convertToHtml({ arrayBuffer })
            .then(({ value }) => setSunEditorContent(value))
            .catch(err => console.error(err));
        };
        reader.readAsArrayBuffer(file);
        break;
      case 'xml':
        reader.onload = (e) => {
          const xmlText = e.target.result;
          setSunEditorContent(xmlText);
        };
        reader.readAsText(file);
        break;
      default:
        setError(`La extension (.${extension}) del archivo no es soportada`);
        return;
    }
    close();
  }

  return (
    <div className={`modal${activeClassName}`}>
      <div className='modal-background' onClick={close} />
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Importar Archivo</p>
          <button className='delete' aria-label='close' onClick={close}></button>
        </header>
        <section className='modal-card-body'>
          <div className='field'>
            <div className={`file has-name is-fullwidth${error ? ' is-danger' : ''}`}>
              <label className='file-label'>
                <input className='file-input' type='file' accept='.xml,.docx' onChange={handleInputChange} />
                <span className='file-cta'>
                  <span className='file-icon'>
                    <i className='fas fa-upload' />
                  </span>
                  <span className='file-label'>Escoger Archivo...</span>
                </span>
                <span className='file-name'>
                  {files.length > 0 ? file.name : 'Ningún archivo seleccionado'}
                </span>
              </label>
            </div>
            {error ? <p className='help is-danger'>{error}</p> : <br className='thin-br' />}
          </div>
          <div
            className='box has-text-centered'
            style={{ border: '2px dashed #dbdbdb', padding: '20px' }}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <p>Arrastre un archivo aqui</p>
          </div>
        </section>
        <footer className='modal-card-foot'>
          <div className='buttons'>
            <button className='button is-success' onClick={handleConfirm}>Confirmar</button>
            <button className='button' onClick={close}>Cancelar</button>
          </div>
        </footer>
      </div>
    </div>
  );
});

export { ImportModal };
